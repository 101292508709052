import { authTokenVar } from "../../apollo/cache";

const IFrame = () => (
  <iframe
    title="Uneeq"
    src={`https://creator.us.uneeq.io/login?provider=veritone&token=${authTokenVar()}`}
    style={{
      width: `100%`,
      height: `100%`,
      margin: 0,
      border: 0,
    }}
  />
);

export default IFrame;
