import { useReactiveVar } from '@apollo/client';
import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import ErrorIcon from '@mui/icons-material/ErrorOutline';
import { appErrorMessageVar } from '../../apollo/cache';

const ErrorPage = () => {
  const defaultMessage = `An unexpected error has occurred.`;
  const appMessage = useReactiveVar(appErrorMessageVar);
  const message = appMessage !== `` ? appMessage : defaultMessage;

  return (
    <Box
      sx={{
        display: `grid`,
        justifyContent: `center`,
        alignContent: `center`,
        padding: 2,
        height: `80%`,
      }}
    >
      <Box
        sx={{
          display: `grid`,
          justifyItems: `center`,
          gap: 2,
        }}
      >
        <ErrorIcon
          color="error"
          sx={{
            fontSize: `60px`,
          }}
        />
        <Typography
          sx={{
            fontFamily: `Nunito`,
            fontSize: `20px`,
            margin: `15px 25px`,
            textAlign: `center`,
          }}
          variant="h6"
        >
          {message}
        </Typography>
      </Box>
    </Box>
  );
};

export default ErrorPage;
