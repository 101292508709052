import { useEffect } from 'react';
import { useReactiveVar } from '@apollo/client';
import Cookies from 'js-cookie';
import {
  authTokenVar,
  isAuthenticatedVar,
} from "../apollo/cache";
import generateOAuthUrl from '../utils/generateOAuthUrl';
import getAccessTokenFromUrl from '../utils/getAccessTokenFromUrl';

const useAuthentication = () => {
  const isAuthenticated = useReactiveVar(isAuthenticatedVar);

  useEffect(() => {
    if (!isAuthenticated) {
      const accessToken = getAccessTokenFromUrl(window.location);
      if (accessToken) {
        Cookies.set(process.env.REACT_APP_TOKEN_COOKIE, accessToken);
        isAuthenticatedVar(true);
        authTokenVar(accessToken);
      } else {
        window.location.replace(generateOAuthUrl());
      }
    } else {
      window.history.pushState({}, ``, `/`);
    }
  }, [isAuthenticated]);

  return isAuthenticated;
};

export default useAuthentication;
