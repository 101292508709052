import {
  ApolloProvider,
} from '@apollo/client';
import { BrowserRouter as Router } from 'react-router-dom';
import { createRoot } from 'react-dom/client';
import App from './App';
import apolloClient from './apollo/client';

const container = document.getElementById(`root`);
const root = createRoot(container!);

root.render(
  <ApolloProvider client={apolloClient}>
    <Router>
      <App />
    </Router>
  </ApolloProvider>,
);
