import { useEffect } from "react";
import { useLocation } from 'react-router-dom';
import { appErrorMessageVar, isAppErrorVar } from "../apollo/cache";

const useErrorHandling = () => {
  const location = useLocation();

  useEffect(() => {
    isAppErrorVar(false);
    appErrorMessageVar(``);
  }, [location]);
};

export default useErrorHandling;
