import { InMemoryCache, makeVar } from '@apollo/client';

export const isAuthenticatedVar = makeVar<boolean>(false);
export const authTokenVar = makeVar<string>(``);
export const isAppErrorVar = makeVar<boolean>(false);
export const appErrorMessageVar = makeVar<string>(``);
export const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        isAuthenticated: {
          read: () => isAuthenticatedVar,
        },
      },
    },
  },
});
