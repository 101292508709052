// import * as aiware from 'aiware-js'

import { useReactiveVar } from '@apollo/client';
import { useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Navigate, Route, Routes } from 'react-router';
import { Box } from '@mui/system';
import useAuthentication from '../hooks/useAuthentication';
import useErrorHandling from '../hooks/useErrorHandling';
import {
  authTokenVar,
  isAppErrorVar,
  isAuthenticatedVar,
} from '../apollo/cache';
import '../assets/styles/base.css';
import IFrame from '../features/IFrame';
import ErrorPage from '../features/ErrorPage';

const App = () => {
  useAuthentication();
  useErrorHandling();
  const isAuthenticated = useReactiveVar(isAuthenticatedVar);
  const authToken = useReactiveVar(authTokenVar);
  const isError = useReactiveVar(isAppErrorVar);

  useEffect(() => {
    // @ts-ignore
    window.aiware.init(
      {
        baseUrl: process.env.REACT_APP_VERITONE_GRAPHQL_ENDPOINT,
        applicationId: process.env.REACT_APP_VERITONE_APP_ID,
        scopedStyles: true,
        authToken,
      },
      () => {
        // @ts-ignore
        window.aiware.mountWidget({
          name: `APP_BAR`,
          elementId: `app-bar`,
          config: {
            title: `Avatar`,
            help: true,
            backgroundColor: `#232236`,
            zIndex: 1000,
            betaFeatures: true,
            notifications: true,
          },
        });
      },
    );
  }, []);

  if (!isAuthenticated) {
    return null;
  }

  const content = isError ? (
    <ErrorPage />
  ) : (
    <ErrorBoundary fallback={<ErrorPage />}>
      <Routes>
        <Route
          path="/" // handle redirect from bad path
          element={<IFrame />}
        />
        <Route
          path="*"
          element={<Navigate to="/" replace />}
        />
      </Routes>
    </ErrorBoundary>
  );

  return (
    <Box
      style={{
        display: `grid`,
        gridTemplateRows: `30px 1fr`,
        height: `calc(100% - 55px)`,
      }}
    >
      <header id="app-bar" />
      <main>{content}</main>
    </Box>
  );
};

export default App;
